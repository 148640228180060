App.helper = {
  capitalize(value) {
    return value.replace(/(^|\s)([a-z])/g, (m, p1, p2) => p1 + p2.toUpperCase());
  },

  getCurrentPage() {
    if (!$('body').attr('id')) {
      return '';
    }

    const bodyId = $('body').attr('id').split('_');
    const action = this.capitalize(bodyId.pop());
    const controller = bodyId;

    for (let i = 0; i < controller.length; i += 1) {
      controller[i] = this.capitalize(controller[i]);
    }

    const pageName = controller.join('') + action;

    return pageName;
  },

  insertToTextbox(areaId, text) {
    var back, br, front, ieRange, range, scrollPos, strPos, txtarea;
    txtarea = document.getElementById(areaId);
    if (!txtarea) {
      return;
    }
    scrollPos = txtarea.scrollTop;
    strPos = 0;
    br = txtarea.selectionStart || txtarea.selectionStart === '0' ? 'ff' : document.selection ? 'ie' : false;
    if (br === 'ie') {
      txtarea.focus();
      range = document.selection.createRange();
      range.moveStart('character', -txtarea.value.length);
      strPos = range.text.length;
    } else if (br === 'ff') {
      strPos = txtarea.selectionStart;
    }
    front = txtarea.value.substring(0, strPos);
    back = txtarea.value.substring(strPos, txtarea.value.length);
    txtarea.value = front + text + back;
    strPos = strPos + text.length;
    if (br === 'ie') {
      txtarea.focus();
      ieRange = document.selection.createRange();
      ieRange.moveStart('character', -txtarea.value.length);
      ieRange.moveStart('character', strPos);
      ieRange.moveEnd('character', 0);
      ieRange.select();
    } else if (br === 'ff') {
      txtarea.selectionStart = strPos;
      txtarea.selectionEnd = strPos;
      txtarea.focus();
    }
    txtarea.scrollTop = scrollPos;
  }

}
