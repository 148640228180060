App.MessagesIndex = () => {
  return {
    init
  }

  function init() {
    onChangeToggleActived();
  }

  function onChangeToggleActived() {
    $("[name='message[actived]']").on('change', function(e) {
      $(this).parents('form').submit();
    });
  }
};
