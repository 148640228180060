App.MessagesNew = () => {
  return {
    init
  }

  function init() {
    onClickTemplateField();
  }

  function onClickTemplateField() {
    $(".template-field").on('click', function(e) {
      let text = '{{' + $(this).data('code') + '}}';
      App.helper.insertToTextbox('message_content', text);

      e.preventDefault();
    });
  }
};

App.MessagesEdit = App.MessagesNew
App.MessagesCreate = App.MessagesNew
App.MessagesUpdate = App.MessagesNew

